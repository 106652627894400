/*tablet styles*/
@media(min-width: 1200px) { 
    #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler {
    display: none;
 }
 #main-navigation-wrapper .nav > .dropdown > a {
    display: block;
    text-transform: uppercase;
    font-weight:bold;
    font-family: 'Lato', sans-serif;
    padding: 30px 25px;
    color: #222;
    font-size: .9em;
    font-weight: bold;
}

 #main-navigation-wrapper .nav > .dropdown > .search{
     padding-top: 30px;
     padding-left: 20px;
     
 }
 #main-navigation-wrapper .navbar-nav .dropdown-expander {
    display: none; 
 }
 
}
@media (min-width: 992px) and (max-width: 1199px){
        #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler {
    display: none;
 }
 #main-navigation-wrapper .nav > .dropdown > a {
    display: block;
    text-transform: uppercase;
    font-weight:bold;
    font-family: 'Lato', sans-serif;
    padding: 40px 15px;
    color: #222;
    font-size: .9em;
    font-weight: bold;
}
 #main-navigation-wrapper .nav > .dropdown > .search{
     padding-top: 38px;
     padding-left: 20px;
     
 }
    .our-futured-services .box  {
    margin-top: 60px;
}
    .our-futured-services .box  .icon-box{
    margin-left: 30%;
}
.cd-slider-nav p{
   font-size: 18px; 
}
.cd-slider-nav li .slide-number {
    
    margin-left: 40%;
}

.numbers .box {
    display: block;
    margin: 10px;
}
 #main-navigation-wrapper .navbar-nav .dropdown-expander {
    display: none; 
 }

.cd-slider-nav li {
    padding-top: 0;
    text-align: center;
}
.cd-slider-nav li a{
    
    text-align: center;
}
.testimonials-three .item {
    margin-left: 150px;
}
}

@media(min-width: 769px) and (max-width: 991px) {
     .main-header .logo{
        text-align:center;
        margin-bottom: 30px;
    }
     .main-header .button-wraper {
        text-align:center;
        display:block;
        margin-bottom: 30px;
        margin-right: 37%;
    }
        .main-header .button-wraper .button{
        float: right;
    }
     #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler {
    display: none;
 }
 #main-navigation-wrapper .nav > .dropdown > a {
    display: block;
    text-transform: uppercase;
    font-weight:bold;
    font-family: 'Lato', sans-serif;
  
    color: #222;
    font-size: .9em;
    font-weight: bold;
}
 #main-navigation-wrapper .nav > .dropdown > .search{
     padding-top: 11px;
     padding-left: 20px;
     
 }
.navbar-header {
    margin-left: 0;
    margin-right: 0;
    display: block;
    margin-bottom:30px;
}
.top-line .social-icons{
    display: block;
    clear: both;
    text-align: center;
} 
.numbers .box {
    display: block;
    margin: 10px;
    float: left;
}
.numbers h2 {
    margin-bottom: 20px;
}

.news .questions {
    margin-top: 40px;
}
.our-team  .members {
    margin-top: 30px;
    display: inline-block;
}
.our-team  .members .member{
    margin-top: 30px;
    margin-right: 10px;
}
.main-footer h2 {
    margin-bottom: 0;
    margin-top: 20px;
}
.main-footer .sec-tab {
    padding-left: 15px;
    margin: 20px 0;
}
.main-footer .solutions li {
    margin-top: 10px;
}
.main-footer .solutions {
    margin-top: 20px;
}
 #main-navigation-wrapper .navbar-nav .dropdown-expander {
    display: none; 
 }
 .why-choose-us .item .img-box {
    width:350px;
    margin-top: 20px;
    
}
.latest-news .item {
    width: 350px;
    margin-bottom: 20px;
}
.latest-news-third .item {
    width: 350px;
    margin-bottom: 20px;
}
.cd-slider-nav li .text-box{
    display: none;
}
.cd-slider-nav li .slide-number{
   margin-left: 32%;
}
.testimonials-three .owl-theme .owl-nav .owl-prev {
    float: left;
    margin-right: 0;
}
.testimonials-three .owl-theme .owl-nav .owl-next {
    float: right;
    margin-left: 0;
}
.testimonials-three .item {
    margin-left: 50px;
}

#minimal-bootstrap-carousel .slide-3 .carousel-caption .content {
    padding-left: 50px;
}
#minimal-bootstrap-carousel .carousel-caption .content {
    padding-left: 50px;
}
}
@media(max-width: 768px) {
    .main-header .logo{
        text-align:center;
        margin-bottom: 30px;
    }
         .main-header .button-wraper {
        text-align:center;
        display:block;
        margin-bottom: 30px;
        margin-right: 37%;
    }
    
    #main-navigation-wrapper .navbar-brand {
    margin-bottom: 40px;
    display: inline-block;
    text-align: center;
    margin-left:0;
    float: none;
}
   #main-navigation-wrapper .navbar-header {
   background: transparent;
}

    .navbar-nav {
        margin: 0;
    }
    
        #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler {
        background-color: transparent;
        background-image: none;
        border: 1px solid #000;
        border-radius: 4px;
        padding: 9px 9px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 8px;
        margin-right: 10px;
        z-index: 999;
    }
   #main-navigation-wrapper.navbar-default {
  background: transparent;
  border: none;
  min-height: auto;
  background: #fbad1a;
  border-top: 1px solid #38374C;
  border-radius: 0;
  margin: 0;
  padding: 20px 0 0 0;
}
    .navbar-default .navbar-toggle:hover {
        border-color: #C4D114;
    }
    .navbar-default .navbar-toggle .icon-bar {
        background: #000;
    }
     .main-menu-two .navbar-default .navbar-toggle, .icon-bar {
        background: #FFFFFF;
        transition: .3s ease;
    }
    
    .navbar-default .navbar-toggle:hover .icon-bar {
        background: #fbad1a;
    }
    .navbar-collapse.collapse {
        display: none !important;
        height: 0 !important;
        padding-bottom: 0;
        overflow: auto !important;
        width: 100%;
    }
    .navbar-default .navbar-collapse {
        background: #FFFFFF;
        padding: 0px 0px !important;
        padding-bottom: 0px !important;
        border: 1px dashed rgba(255, 255, 255, 0.4);
        margin: 0;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
        height: auto !important;
        max-height: 70vh;
        margin-top: 0px;
    }
    .navbar-collapse.in {
        overflow-y: auto;
    }
    .header #main-navigation .nav {
        width: 100%;
        text-align: left;
    }

    #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler {
        background-color: transparent;
        background-image: none;
        border: 1px solid #072d4d;
        border-radius: 4px;
        padding: 9px 9px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 8px;
        margin-right: 10px;
        z-index: 999;
    }
    #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler .icon-bar {
        background-color: #072d4d;
        border-radius: 1px;
        display: block;
        height: 1px;
        width: 20px;
    }
    #main-navigation-wrapper .navbar-nav .dopdown-nav-toggler .icon-bar + .icon-bar {
        margin-top: 4px;
    }

    #main-navigation-wrapper .nav {
        width: 100%;
    }
    #main-navigation-wrapper .navbar-form {
        width: 100%;
        display: block;
        border: none;
    }
    #main-navigation-wrapper .nav > li {
        display: block;
        padding: 0;
        width: 100%;
    }
    #main-navigation-wrapper .nav > li > a {
        display: block;
        padding: 15px 15px;
        width: 100%;
        position: relative;
        color: #2d2d2d;
    }
    .main-menu .search li.download {
        display: none;
        
    }
    #main-navigation-wrapper .nav > li:first-child a {
        padding: 15px 15px;
    }
    #main-navigation-wrapper .nav > li + li > a {
        padding: 15px 15px;
        border-top: 1px dashed rgba(114, 114, 114, 0.4);
        position: relative;
    }
    #main-navigation-wrapper .navbar-nav > li > .dropdown-submenu {
       
        
        position: relative;
        width: 100%;
        opacity: 1;
        visibility: visible;
        left:auto;right:auto;
        transform: translate3d(0px, 0px, 0px);
        transition: none !important;
        display: none;
        top:auto;
        float: none;
    }
    #main-navigation-wrapper ul.dropdown-submenu.expand {
    
  display: block;
}


 .searchBtn {
 	display: none;
 }
  #main-navigation-wrapper .nav > .dropdown > .search{
     padding-top: 10px;
     padding-bottom: 10px;
     padding-left: 20px;
     color: #FFFFFF;
 }

.menuToggle {
    display: block;
    background: rgb(76, 67, 65);
    width: 8em;
    margin-bottom: .2em;
    position: relative;
}
#main-navigation-wrapper ul.dropdown-submenu.expand {
    max-height: 20em;
}
.offer-wraper .img-box {
    width: 49%;
    overflow: hidden;
}
.offer-wraper .item .btn {
    border: none;
    background: #fbad1a;
    color: #ffffff;
    border-radius:0px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    padding: 11px 25px;
    position: absolute;
    top: 85px;
    left: 34%;
}
.numbers .box {
    display: block;
    margin: 10px;
    float: left;
}
.numbers h2 {
    margin-bottom: 20px;
}
.news .questions {
    margin-top: 40px;
}
.our-team  .members {
    margin-top: 30px;
    
}
.our-team  .members .member{
    
    margin-top: 30px;
    margin-right: 10px;
}
.main-footer h2 {
    margin-bottom: 0;
    margin-top: 20px;
}
.main-footer .sec-tab {
    padding-left: 15px;
    margin: 20px 0;
}
.main-footer .solutions li {
    margin-top: 10px;
}
.main-footer .solutions {
    margin-top: 20px;
}
header.two .top-line-two .welcome ul li{
    display: block;
    text-align: left;
    
}
header.two .top-line-two .social-icons ul li{
    display: block;
    text-align: center;
    float: left;
}
#minimal-bootstrap-carousel .slide-3 .carousel-caption .content {
    padding-left: 20px;
}
 .why-choose-us .item .img-box {
    width:350px;
    margin-top: 20px;
    
}
.latest-news .item {
    width: 350px;
    margin-bottom: 20px;
}
.latest-news-third .item {
    width: 350px;
    margin-bottom: 20px;
}
.cd-slider-nav nav {
    margin-left: 20px;
}
.cd-slider-nav li {
    width: 110px;
    padding-top: 5px;
    height: 70px;
}
.cd-slider-nav li a{
    padding-top: 5px;
}
.cd-slider-nav li .text-box{
    display: none;
}
.cd-slider-nav li .slide-number{
   margin-left: 25%;
}


.testimonials-three .owl-theme .owl-nav .owl-prev {
    float: left;
    margin-right: 0;
}
.testimonials-three .owl-theme .owl-nav .owl-next {
    float: right;
    margin-left: 0;
}
.testimonials-three .item {
    margin-left: 0px;
}
}

@media (max-width: 640px) {
       .main-header .button-wraper {
        text-align:center;
        display:block;
        margin-bottom: 30px;
        margin-right: 30%;
    }
    .offer-wraper .img-box {
    width: 60%;
    overflow: hidden;
}
.offer-wraper .item .btn {
    border: none;
    background: #fbad1a;
    color: #ffffff;
    border-radius:0px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    padding: 11px 25px;
    position: absolute;
    top: 85px;
    left: 32%;
}
.numbers {
    padding: 15px 0;
}
  .numbers .box {
    display: block;
    margin: 10px;
    float: left;
}
.numbers h2 {
    margin-bottom: 20px;
}
.news .questions {
    margin-top: 40px;
}
.our-team  .members {
    margin-top: 30px;
    
}
.our-team  .members .member{
    margin-top: 30px;
    
}
.main-footer h2 {
    margin-bottom: 0;
    margin-top: 20px;
}
.main-footer .sec-tab {
    padding-left: 15px;
    margin: 20px 0;
}
.main-footer .solutions li {
    margin-top: 10px;
}
.main-footer .solutions {
    margin-top: 20px;
}
.testimonials-three .item {
    width: 100%;
}
.testimonials-three .testimonials-author .author-img-wraper {
    padding: 0 230px;
}
}

@media (max-width: 480px) {
          .main-header .button-wraper {
        text-align:center;
        display:block;
        margin-bottom: 30px;
        margin-right: 20%;
    }
    .main-header ul {
        margin-left: 0;
        padding-left: 0;
    }
     .offer-wraper .img-box {
    width: 83%;
    overflow: hidden;
}
.offer-wraper .item .btn {
    border: none;
    background: #fbad1a;
    color: #ffffff;
    border-radius:0px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    padding: 11px 25px;
    position: absolute;
    top: 85px;
    left: 32%;
}
.news .our-news .news1 figure {
    display: block;
    width: 100%;
    margin-bottom: 15px;
}
.news .our-news .news1 .text-box {
    display: block;
    
}
.news .questions {
    margin-top: 40px;
}
.news .questions h2:before {
    content: "";
    position: absolute;
    top: 75px;
    left: 15px;
    width: 50px;
    height: 4px;
    background: #fbad1a;
}
.our-team  .members {
    margin-top: 30px;
    
}
.our-team  .members .member{
    margin-top: 30px;
    
}
.main-footer h2 {
    margin-bottom: 0;
    margin-top: 20px;
}
.main-footer .sec-tab {
    padding-left: 15px;
    margin: 20px 0;
}
.main-footer .solutions li {
    margin-top: 10px;
}
.main-footer .solutions {
    margin-top: 20px;
}header.two .top-line-two .welcome ul li{
    display: block;
    text-align: left;
    
}
header.two .top-line-two .social-icons ul li{
    display: block;
    text-align: center;
    float: left;
}
header.two .top-line-two .social-icons ul {
    overflow: hidden;
    padding: 0;
}
header.two .top-line-two .social-icons{
    float: left;

}
    .cd-slider-nav li {
    width: 80px;
    padding-top: 5px;
    height: 70px;
}
    .cd-slider-nav li .slide-number{
    margin-left: 10%;
}
.testimonials-three .testimonials-author .author-img-wraper {
    padding: 0 170px;
}
}












